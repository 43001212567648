<div class="list-filter">
  <c-row>
    <!-- Manage Content (MAKER, CHECKER, SUPER ADMIN) -->
    <ng-container
      *ngIf="config.mode === 'ROLE_MAKER_ADMIN' || config.mode === 'ROLE_CHECKER_ADMIN' || config.mode === 'ROLE_SUPER_ADMIN'">
      <!-- Language: MAKER, CHECKER, SUPER -->
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputLanguageConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <!-- Status: MAKER, CHECKER, SUPER -->
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input
            [config]="config.mode === 'ROLE_CHECKER_ADMIN' ? inputCheckerStatusConfig: inputMakerStatusConfig"
            (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <!-- Active Period: MAKER, CHECKER, SUPER -->
      <c-col *ngIf="config.has_active_period !== false" sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-datetimerangepicker-input [config]="inputActivePeriodConfig" (onChange)="returnFilterValue($event)">
          </app-datetimerangepicker-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Pending Approval (CHECKER) -->
    <ng-container *ngIf=" config.mode === 'CHECKER_PENDING_APPROVAL'">
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputCategoryConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputLanguageConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Asset Library -->
    <ng-container *ngIf="config.mode === 'ASSET_LIBRARY'">
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputFileTypeConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputSortingConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Manage Admin -->
    <ng-container *ngIf="config.mode === 'ADMINS_LIST'">
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputAdminStatusConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Audit Trail -->
    <ng-container *ngIf="config.mode === 'AUDIT_TRAILS'">
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputAuditRoleConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputAuditStatusConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>

      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputAuditEventConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
      <c-col *ngIf="config.has_active_period !== false" sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-datetimerangepicker-input [config]="inputAuditDateRangeConfig" (onChange)="returnFilterValue($event)">
          </app-datetimerangepicker-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Manage Partner -->
    <ng-container *ngIf="config.mode === 'PARTNERS_LIST'">
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputPartnerStatusConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputPartnerRankConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
      <c-col sm="6" lg="12" class="mb-4">
        <div class="form-group">
          <app-select-menu-input [config]="inputPartnerMarketConfig" (onChange)="returnFilterValue($event)">
          </app-select-menu-input>
        </div>
      </c-col>
    </ng-container>

    <!-- Mange Content: Content Order -->
    <ng-container *ngIf="config.content_order_status">
      <div *ngIf="config.content_order_status === 'APPROVED'; else ongoingContentOrder"
        class="mt-3 pt-4 mb-3 border-0 border-top border-dashed">
        <button cButton class="btn-link" (click)="onManageOrderButton()">
          <span class="icon-img me-3">
            <svg cIcon name="cilViewCarousel"></svg>
          </span>
          Manage {{config.content_name}}'s order
        </button>
      </div>

      <ng-template #ongoingContentOrder>
        <div #ongoingContentOrder class="card" [class.card-draft]="config.content_order_status === 'DRAFT'"
          [class.card-warning]="config.content_order_status === 'SUBMITTED_FOR_APPROVAL'"
          [class.card-danger]="config.content_order_status === 'REJECTED'">
          <div class="card-body d-flex flex-wrap">
            <span class="icon-img size-lg">

              <ng-container
                *ngIf="config.content_order_status === 'SUBMITTED_FOR_APPROVAL' || config.content_order_status === 'DRAFT'">
                <svg cIcon name="cilAvTimer"></svg>
              </ng-container>

              <ng-container *ngIf="config.content_order_status === 'REJECTED'">
                <svg cIcon name="cilExclamationCircle"></svg>
              </ng-container>

            </span>
            <div class="flex-1 ps-3">

              <p *ngIf="config.content_order_status === 'DRAFT'">
                There's an on-going pending submission on this content order settings.
              </p>

              <p *ngIf="config.content_order_status === 'SUBMITTED_FOR_APPROVAL'">
                There's an on-going pending approval submission on this content order settings.
              </p>

              <p *ngIf="config.content_order_status === 'REJECTED'">
                Previous submission for this content order settings was rejected.
              </p>
              <p *ngIf="config.content_order_status === 'REJECTED'">
                <span class="fs-smaller gray-600">Reason:</span>
                <br><span>{{config.content_order_message ?? '-'}}</span>
              </p>

              <button cButton class="btn-link" size="sm" (click)="onManageOrderButton()">Edit submission</button>

            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </c-row>
</div>