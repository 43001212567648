export enum RoleName {
    SUPER_ADMIN = "ROLE_SUPER_ADMIN",
    SECURITY_ADMIN = "ROLE_SECURITY_ADMIN",
    MAKER = "ROLE_MAKER_ADMIN",
    CHECKER = "ROLE_CHECKER_ADMIN",
}

export enum RoleManagable {
    ROLE_SUPER_ADMIN = "ROLE_MAKER_ADMIN,ROLE_CHECKER_ADMIN,ROLE_SECURITY_ADMIN",
    ROLE_SECURITY_ADMIN = "ROLE_MAKER_ADMIN,ROLE_CHECKER_ADMIN",
    ROLE_MAKER_ADMIN = "",
    ROLE_CHECKER_ADMIN = "",
}

export enum RoleDisplayName {
    ROLE_SUPER_ADMIN = "Super Admin",
    ROLE_SECURITY_ADMIN = "Security Admin",
    ROLE_MAKER_ADMIN = "Maker Admin",
    ROLE_CHECKER_ADMIN = "Checker Admin",
}