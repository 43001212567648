import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class PublicService {

    constructor(
        private http: HttpClient
    ) { }

    // Get market
    getMarket(): Observable<any> {
        return this.http.get(`${environment.domain}${environment.prefixPath}/markets`).pipe(
            map(res => {
                return res;
            })
        );
    }
}