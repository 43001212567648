import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";
import moment from "moment";
import { RoleName } from "../enum/role.enum";

@Injectable({
    providedIn: 'root',
})
export class AuditService {
    constructor(
        private http: HttpClient
    ) { }

    // Get events for audit listing filter
    getEvent(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/events`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get audit listing
    getHistory(event: string, role: string, page: number, size: number, status: string, search: string = '', activePeriod: Date[] = []): Observable<any> {

        let queryParams = new HttpParams();
        event !== 'ALL' ? queryParams = queryParams.append("event", event) : null;
        role !== 'ALL' ? queryParams = queryParams.append("role", RoleName[role.toUpperCase().replace(/ /g, '_') as keyof typeof RoleName]) : null;
        queryParams = queryParams.append("page", page);
        queryParams = queryParams.append("size", size);
        search !== '' ? queryParams = queryParams.append("search", search) : null;
        status !== 'ALL' ? queryParams = queryParams.append("status", status) : null;
        if (activePeriod.length === 2) {
            activePeriod[0] ? queryParams = queryParams.append("from", `${moment(activePeriod[0]).format("YYYYMMDD")}000000`) : null;
            activePeriod[1] ? queryParams = queryParams.append("to", `${moment(activePeriod[1]).format("YYYYMMDD")}235959`) : null;
        }

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/history`, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get statuses for audit listing filter
    getStatus(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/statuses`).pipe(
            map(res => {
                return res;
            })
        );
    }

}